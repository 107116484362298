import React, { useState, useEffect } from "react";
import { useStoreon } from "storeon/react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { message, Tooltip, Upload, Button, Modal } from "antd";
import { AUTH_TOKEN } from "../../constant";
import { FileAddOutlined, EyeOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

const queries = {
 "order": "Orders",
 "shipment":"Shipments",
 "user":"Users",
 "company":"Companies",
 "truck":"Trucks",
 "trailer":"Trailers"
};
const UploadListItem = ({ originNode, file, fileList }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);

  const errorNode = (
    <Tooltip title="Upload Error" getPopupContainer={() => document.body}>
      {originNode.props.children}
    </Tooltip>
  );
  const advancedNode = (
    <Tooltip placement="topLeft" title={(
     "size: "+file.size+" bytes, "+ "type: "+file.type+", "+
     "date: "+moment(file.lastModified).format('DD.MM.YYYY')+", "+
     "time: "+moment(file.lastModified).format('HH:mm:SS')
     )} getPopupContainer={() => document.body}>
      {originNode.props.children}
    </Tooltip>
  );
  return (
    <div ref={ref} >
      {file.status === 'error' ? errorNode : advancedNode}
    </div>
  );
};

const UploadForm = () => {
  const { ui, dispatch } = useStoreon("ui");
  const { visible, dataModel, id } = ui.uploadForm;
  const [fileList, setFileList] = useState();

  const closeForm = () => {
    dispatch("hideUploadForm");
  };

/*
        _and: [
          { hidden: { _eq: false }}
          { order_id: { _eq: $orderId }} 
          { shipment_id: { _eq: $shipmentId}}
          { user_id: { _eq: $userId}}
          { company_id: { _eq: $companyId}}
          { truck_id: { _eq: $truckId}}
          { trailer_id: { _eq: $trailerId}}
        ]
*/
  const { loading, error, data } = useQuery(
    GET_FILES,
    {
      skip: !visible,
      fetchPolicy: "network-only",
      variables: {
        condition: {
          _and: [
            { hidden: { _eq: false }},
            { order_id: dataModel === "order"? { _eq: id }:{}},
            { shipment_id: dataModel === "shipment"? { _eq: id }:{}},
            { user_id: dataModel === "user"? { _eq: id }:{}},
            { company_id: dataModel === "company"? { _eq: id }:{}},
            { truck_id: dataModel === "truck"? { _eq: id }:{}},
            { trailer_id: dataModel === "trailer"? { _eq: id }:{}}
          ]
        }
      },
      // wtf?
      //refetchQueries: ["CountShipmentFiles", "CountOrderFiles"]
    }
  );

  useEffect (() => {
   let _fileList = data?.files.map(file => {
    return {
      ...file,
      url: process.env.REACT_APP_SERVER_URL + "/storage/download/" + file.key
    };
   });
   setFileList(_fileList);
  }, [data]);

  const insertHandler = (d) => {
    console.log('insertHandler: ', d);
  }

  const [insert] = useMutation(INSERT_FILES, {
    refetchQueries: [ "GetFiles", queries[dataModel] ],
    onCompleted: (data) => insertHandler(data),
  });

  const [update] = useMutation(UPDATE_FILES, {
    refetchQueries: [ "GetFiles", queries[dataModel] ]
  });

  const handleChange = res => {
    //setFileList(res.fileList.slice()); // Note: A new object must be used here!!!
    res.fileList.map(file => {
      if (file.response) {
        file.url =
          process.env.REACT_APP_SERVER_URL +
          "/storage/download/" +
          file.response.key;
      }
      return file;
    });

    if (res.file.status === "done") {
      let file = res.file;
      let objects = {
        name: file.name,
        size: file.size,
        key: file.response.key,
        uid: file.uid,
        last_modified: file.lastModifiedDate,
        type: file.type,
        // todo: ?
        // url: file.url,
        bucket: file.response.Bucket,
        order_id: dataModel === "order" ? id : undefined,
        shipment_id: dataModel === "shipment" ? id : undefined,
        user_id: dataModel === "user" ? id : undefined,
        company_id: dataModel === "company" ? id : undefined,
        truck_id: dataModel === "truck" ? id : undefined,
        trailer_id: dataModel === "trailer" ? id : undefined,
      };
      insert({
        variables: {
          objects: objects
        }
      });
    } else if (res.file.status === 'error') {
        message.error(`${res.file.name} file upload failed.`);
      }
  };

  const handleRemove = file => {
    console.log('handleRemove: ', file);
    update({
      variables: { id: file.id, objects: { hidden: true } }
    });
/*
 * todo: add update habdler like followed code?
 *
      const promise: PromiseLike<void | boolean> = deleteArtifact({ filename: file.response });
      promise.then((value: any) => {
        if (value === '' || value instanceof Response && value.status === 205) {
          const index = fileList.indexOf(file);
          const newFileList = fileList.slice();
          newFileList.splice(index, 1);
          setFileList(newFileList);
        }
*/
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // insert file url based on .env REACT_APP_SERVER_URL
/*
  let fileList = data?.files.map(file => {
debugger;
console.log('file: '+file.name+', id='+file.id);
    return {
      ...file,
      url: process.env.REACT_APP_SERVER_URL + "/storage/download/" + file.key
    };
  });
*/


/*
  const handleRemoveId = id => {
    if(id === '-1') {
      return null;
    } else {
      return ( <DeleteOutlined />)
    }
  }
*/
  return (
    <Modal
      visible={visible}
      okText="Ok"
      onOk={closeForm}
      onCancel={closeForm}
      title={`Attachments [${dataModel}]`}
      footer={[
            <Button key="submit" type="primary" loading={loading} onClick={closeForm}>
              Done
            </Button>,
          ]}
    >
      <Upload
        action={process.env.REACT_APP_SERVER_URL + "/storage/upload"}
        action_="https://httpbin.org/post?a=b"
        _defaultFileList={fileList}
        fileList={fileList}
        headers={{
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`
        }}
        multiple
        onChange={res => handleChange(res)}
        onRemove={file => handleRemove(file)}
        itemRender={(originNode, file, currFileList) => {
          return (
            <UploadListItem
              originNode={originNode}
              file={file}
              fileList={currFileList}
            />
          );
        }}
      >
        <Button type="link">
          <FileAddOutlined /> Add file
        </Button>
{/*
  * custom list template
  *
<div>
    {fileList.length < 8 ? uploadButton : null}
    {fileList?.length && fileList.map(item => (
        <div style={{ position: 'relative' }}>
          <img src={item.url} alt="" height={80} />
          <span _style={{ position: "absolute", left: '50%', top: '50%', transform: 'translate(-50%,-50%)', color: '#fff' }}>
          <EyeOutlined />
          {item.name}
          {item.size}
          {handleRemoveId(item.uid)}
          </span>
        </div>
     ))}
  </div>
*/}
      </Upload>
    </Modal>
  );
};

const INSERT_FILES = gql`
  mutation CreateFile($objects: [files_insert_input!]!) {
    insert_files(objects: $objects) {
      affected_rows
    }
  }
`;

//    update_files(_set: $objects, where: { id: { _eq: $id } }) {
const UPDATE_FILES = gql`
  mutation UpdateFile($id: Int!, $objects: files_set_input!) {
    update_files(_set: $objects, where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const GET_FILES = gql`
  query GetFiles($condition: files_bool_exp!) {
    files(where: $condition)
    {
      id
      order_id
      shipment_id
      user_id
      company_id
      truck_id
      trailer_id
      name
      size
      type
      key
      bucket
      uid
      hidden
      created_at
      last_modified
    }
  }
`;

export default UploadForm;
