import React, { useState, useEffect } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload, Form, Button } from 'antd';
import { getUserTenant } from "../../../auth/authHelper";
import { AUTH_TOKEN } from "../../../constant";

const { Dragger } = Upload;

/////////////////
const UploadCert = ({onSelectFile, fileInfo, botArgs, startUpload, onBotFinish, ...otherProps}) => {
  const [fileList, setFileList] = useState(fileInfo);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    console.log(`UploadCert:  mounted.`);
    return () => console.log(`UploadCert: unmounting.`);
  }, []);

  useEffect(() => {
    setFileList(fileInfo?[fileInfo]:[]);
  }, [fileInfo]);

const props = {
  name: 'file',
  accept: 'application/pdf',
  multiple: false, // just single file at once
  maxCount: 1, // limit of files to add
  action: process.env.REACT_APP_SERVER_URL + '/certificates/upload',
  data: {}, // filled later
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      // message.success(`${info.file.name} file uploaded successfully.`);
      console.log(`UploadCert: ${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`, 0);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
  onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);

    if (onSelectFile) { 
      console.log('beforeUpload: find handler onSelectFile, try to call');
      try  { onSelectFile(null); } catch (e) {  alert(e); }
      console.log('beforeUpload: fateer handler onSelectFile');
    }    
  },
  beforeUpload: (file) => {
    if (props.maxCount === 1)
      setFileList([file]);
    else
      setFileList([...fileList, file]);

    // MUST BE a single file! (props => multiple: false)
    if (onSelectFile) { 
      console.log('beforeUpload: find handler onSelectFile, try to call');
      try  { onSelectFile(file); } catch (e) {  alert(e); }
      console.log('beforeUpload: fateer handler onSelectFile');
    }    

    console.log('beforeUpload: return false for custom upload');
    return false;
  },
  fileList,
};

//////////////
const handleUpload = () => {
    // alert(JSON.stringify(fileList));
    console.log("UploadCert.handleUpload: fileList=", fileList, "props:", props);

debugger;

    const formData = new FormData();
    fileList.forEach((file) => {
      // formData.append('files[]', file);
      formData.append('file', file);
    });
    Object.keys(botArgs).map((key) => formData.append(key, botArgs[key]));
    //formData.append('data', JSON.stringify(botArgs));
    setUploading(true);

/*
 * it we start using https://www.npmjs.com/package/form-data
 *

    const headers = Object.assign({
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    }, formData.getHeaders());
*/

    // You can use any AJAX library you like
    fetch(props.action, {
      method:  'POST',
//      headers: headers,
      headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
//          "Content-Type": "multipart/form-data",
      },
      body: formData,
      //data: formData,
    })
      .then((res) => res.json())
      .then((answer) => {

        // alert("UploadCert: "+JSON.stringify(answer));
        console.log("UploadCert: ", answer);

        if (answer.error) {
           console.log('UploadCert: handleUpload: server return error: ', answer);
           throw new Error('server return error: '+answer.error);
        }

// DISABLED for debug ? 
//        setFileList([]);

        //message.success('upload successfully.');
        console.log('UploadCert: upload successfully.');

        if (onBotFinish) onBotFinish(answer);

      })
      .catch((e) => {

        console.log("UploadCert.handleUpload:", e);
        // message.error('upload failed.'+ e.message); 
        message.error({
          content:  'upload failed. '+ e.message,
          key:      'upload_failed',
          duration: 10, // 0 - not autohide
          onClick:  (e) => message.destroy('upload_failed'),
        });

        if (onBotFinish) onBotFinish({error: e});
      })
      .finally(() => {
        setUploading(false);
      });
};

  useEffect(() => {
    if (startUpload) { 
      if (fileList.length) {
        console.log('UploadCert: startUpload fired');
        handleUpload();
      } else {
        if (onBotFinish) onBotFinish({error: { message: "Please select file ..."}});
      }
    }
  }, [startUpload]);

return (
<>
<Form.Item name="dragger" valuePropName="dragger">
  <Dragger 
    {...props} 
    {...otherProps} 
    style={{paddingLeft: '10px', paddingRight: '10px'}}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Click or drag certificate file to this area to upload</p>
    <p className="ant-upload-hint">You can load only one certificate file (*.pdf)</p>
  </Dragger>
</Form.Item>
{/*
  // switch to posponed upload via changing `startUpload` flag
  <Button
     type="primary"
     onClick={(e) => handleUpload(e) }
     disabled={fileList.length === 0}
     loading={uploading}
     style={{ marginTop: 16, }}
  >
     {uploading ? 'Uploading' : 'Start Upload'}
  </Button>
*/}
</>
)
};

export default UploadCert;



///
/*
const handleChange = res => {
    res.fileList.map(file => {
      if (file.response) {
        file.url =
          process.env.REACT_APP_SERVER_URL +
          "/storage/download/" +
          file.response.key;
      }
      return file;
    });

    if (res.file.status === "done") {
      let file = res.file;
      let objects = {
        name: file.name,
        size: file.size,
        key: file.response.key,
        uid: file.uid,
        last_modified: file.lastModifiedDate,
        type: file.type,
        bucket: file.response.Bucket,
        order_id: dataModel === "order" ? id : undefined,
        shipment_id: dataModel === "shipment" ? id : undefined
      };
      insert({
        variables: {
          objects: objects
        }
      });
    }
  };
*/