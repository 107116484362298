import React, { useRef, useState, useEffect } from "react";
import moment from "moment";
import gql from "graphql-tag";
import path from "path";
import { useStoreon } from "storeon/react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Col, Row } from "antd";
import { Steps, message, Form, Input, Tag, Space, Table, Radio, Button, Typography } from "antd";

import { ArrowRightOutlined, MailOutlined, DownloadOutlined } from '@ant-design/icons';
import { getUserTenant } from "../../../auth/authHelper";

import UploadCert from "./UploadCert.js";
import RegNumInput from "../../shared/RegNumInput";
const RegNumItem = RegNumInput;

const { Text, Link } = Typography;
const { Step } = Steps;

//////////////////////////////////////////////////
const TracksList = ({form, input_ref, company_id, onChange, ...otherProps}) => {
  const { loading, error, data } = useQuery(GET_TRUCKS, { variables: {clientId: company_id }, skip: !company_id });
  
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

//      {data.trucks.map((r, i) => <Tag onClick={(e) => form.setFieldValue("reg_num", {r.reg_number})} >{r.reg_number}</Tag>) }
//      {data.trucks.map((r, i) => <Tag onClick={(e) => form.setFieldsValue({reg_num: e.target.innerText}) } >{r.reg_number}</Tag>) }
//      {data.trucks.map((r, i) => <Tag onClick={(e) => input_ref.current.input.value = e.target.innerText } >{r.reg_number}</Tag>) }

//      {data.trucks.map((r, i) => <Tag key={r.reg_number} onClick={(e) => form.setFieldValue("reg_number", e.target.innerText) } >{r.reg_number}</Tag>) }

  if (data?.trucks.length) 
    return  (<Space wrap><b>Trucks: </b> 
      {data.trucks.map((r, i) => <Tag key={r.reg_number} onClick={(e) => onChange(e.target.innerText) } >{r.reg_number}</Tag>) }
   </Space>);
  else 
    return ('NO TRUCKS ... please enter manualy:');  
}

//////////////////////////////////////////////////
const CertificatesPage = () => {
  const [ form ] = Form.useForm();
  const { dispatch, auth } = useStoreon("selection", "auth");
  const [ company, setCompany ] = useState({});
  const [ regNum, setRegNum ] = useState();
  const [ regNumChecked, setRegNumChecked ] = useState(false);
  const [ stage, setStage ] = useState(0);
  const [ already, setAlready ] = useState(false);
  const [ replacement, setReplacement ] = useState(false);
  const [ certType, setCertType ] = useState("paid");
  const [ fileInfo, setFileInfo ] = useState();
  const [ isCertFreeAcn, setIsCertFreeAcn ] = useState(false);
//  const [ isCertTypeAcn, setIsCertTypeAcn ] = useState(false);
  const [ startUpload, setStartUpload ] = useState(false);
  const [ operation, setOperation ] = useState();
  const [ botDone, setBotDone ] = useState();

  const RegNumItem_ref = useRef(null);

  // get certificates by registration number for today
  const [ getCertsByRNtoday, { loading: cert_loading, data: cert_data, error: cert_error } ] = useLazyQuery(GET_CERTIFICATES_TODAY, {fetchPolicy: 'network-only'});
  const { loading, error, data } = useQuery(GET_COMPANIES);

  useEffect(() => {
    console.log(`CertificatesPage:  mounted.`);
    return () => console.log(`CertificatesPage: unmounting.`);
  }, []);

  ///////////
  useEffect(() => {
    switch (stage) {
      case 0:
          if (botDone) {
          form.setFieldValue("reg_number", '');
          setRegNumChecked(false);
          setAlready(false);
          // done
          setCompany({});
          setRegNum();
          setReplacement(false);
          setCertType("paid");
          setFileInfo();
          setIsCertFreeAcn(false);
          setStartUpload(false);
          setOperation();
          setBotDone();
          }
          break;
      case 1:
          if (RegNumItem_ref?.current) RegNumItem_ref.current.focus();
          setRegNumChecked(false);
          setAlready(false);
          break;
    }
  }, [stage]);

  // got certs for reg number
  useEffect(() => {
    setRegNumChecked(true);
    if (cert_data?.crt_files.length > 0) setAlready(true);
  }, [cert_data]);

  const onChangeCompany = (e) => {
    console.log('radio checked', e.target.value);
    const target_company = data.companies.find((d) => d.id == e.target.value);
    setCompany(target_company?target_company : {});
  };

  const onChangeRegNum = (e) => {    
//  if (e.target.value.length === 0) return;
    // RegNumInput: `normalize` does not have time to update the last entered data ... fix it
    setRegNum ((e.target.value||'').toUpperCase());
    // new number not checked yet

    // now we at state == 2 and setStage(2) not fired useEffect([stage]);
//?  setStage(2);

    // set this flags right here
    // setRegNumChecked(false); => setStage(2) do it ...
    setRegNumChecked(false);
    setAlready(false);

    console.log('regnum:', e.target.value);
  };

  const onSelectFile = (e) => {
    console.log('file:', e);
    setFileInfo (e);

    // setStage(e ? 5 : 4);
    if (e) setStage(stage + 1);
  };

  const onBotStartHandler = () => {
    console.log('onBotStartHandler: fired');

    // if we want to move on next tab
    //setStage(stage+1);  

    setStartUpload(true);
  };

  const onBotFinishHandler = (answer) => {
    setStartUpload(false);
    
    //alert("Page:"+JSON.stringify(answer));
    console.log("Page:", answer);
    const data = answer?.result?.[0];

    if (answer?.error || !data || !Object.hasOwn(data, 'code')) {
      message.error(`file upload failed. ${answer?.error ? answer?.error?.message : "bad answer structure"}`);
      return;
    }
/*
 * email ok => no filename
 *
    else if (!Object.hasOwn(data, 'filename')) {
      message.error(`file upload failed. ${data.err_text ? data.err_text : "unknown error. code="+data.code}`);
      return;
    }
*/
    const {code, filename, err_text } = data;

/*
    if (!code) {
      message.error(`proccesing error. ${data.err_text ? data.err_text : "unknown error. code="+data.code}`);
      setBotDone(data);
      return;
    }
*/
    if (operation == "download" && code == 0) {
        if (!Object.hasOwn(data, 'filename')) {
          message.error(`file upload failed. ${data.err_text ? data.err_text : "unknown error. code="+data.code}`);
          return;
        }

        handleDownload(process.env.REACT_APP_SERVER_URL + '/certificates/download/'+filename, path.basename(filename))
        .then((d) => {
          console.log('handleDownload: ok');
          setBotDone(data);
        })
        .catch((error) => {
          console.log("handleDownload: Error fetching the file:", error);
          message.error(`file download failed. ${error}`);
        })
        .finally(() => {
          console.log('handleDownload: finalyze -> handleDownload');
        });         
   } else {
        setBotDone(data);
   }
  };

  const onDone = (e) => {
    console.log('done:', e);
    // temporary switch off for debug
    setStage(0);  
    // move to stage.1
    // setStartUpload(false); 
  }

  //////////////////////
  const handleDownload = (url, fileName, answer) => {
    console.log("handleDownload: begin");
    const f = fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName || "downloaded-file";
        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        console.log("handleDownload: done:");
      })
/*
      .catch((error) => {
        console.log("handleDownload: Error fetching the file:", error);
      })
      .finally(() => {
         console.log('handleDownload: finalyze -> handleDownload');
      });
*/
    console.log("handleDownload: end");
    return f;
  };

  ///////////
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  ///////////
  /////////////////////////////////////////////////////////////////////
const next = () => setStage(stage + 1);
const prev = () => setStage(stage - 1);

const steps = [
  /* Client ********************************************************/
  {
    title: 'Client',
    subTitle: <b>{company?.short_name}</b>,
    content: 
    <Space direction="vertical" size={2}>
    <Radio.Group 
      disabled={stage > 6}
      onChange={onChangeCompany} 
      value={company.id} 
      optionType="button" 
      buttonStyle="solid" 
      style={{ marginTop: 2 }}
     >
      <Space direction="vertical" size={2}>
        {data.companies.map((r, i) => <Radio key={r.id} value={r.id}>{r.name}</Radio>) }
        <Button 
          disabled={!company.id || stage > 6} 
          icon={<ArrowRightOutlined />} 
          style={{ marginTop: 8, backgroundColor: company.id?'lightgreen':null }}
          onClick= {((e) =>  { 
            setStage(stage+1);
            if (RegNumItem_ref?.current) RegNumItem_ref.current.focus();
          })}
        >Next { company.id && <> ... with  <b>{company.short_name}</b></>}</Button>
      </Space>
    </Radio.Group>
    </Space>
  },
  /* Registration number ********************************************************/
  /* Input Registration number and checking cert on it */
  /* that the certificate already exists */
  {
    title: 'Reg.Number',
    subTitle: <b>{regNum}</b>,
    content: <>
{/*
  * 
  * There is no linking of trucks to companies and there may not be in the future (as previously planned).
  * Perhaps later it will be possible to make a selection from the certificates table and 
  * from there take a list of trucks according to certain criteria
  *
      <TracksList form={form} input_ref={RegNumItem_ref} company_id={company.id} onChange={(v) => {setRegNum(v);}} />
*/}
      <RegNumItem
          input_ref={RegNumItem_ref}
          form={form}
          label="Reg.Number"
          name="reg_number"
          _dependencies={["company_id"]}
          country_id={company.country_id}
          company_id={company.id}
          onChange={onChangeRegNum}
          value={regNum}
          style={{width: 200,}}
      />
      {already && <>
          <Text type="danger">This certificate already has been processed! Are you trying to issue the replacement?</Text>
          <Button
            style={{ marginTop: 8 }}
            onClick= {(e) =>  { setReplacement(true); }}
            disabled={replacement}
          >Yes
          </Button>
          </>
      }
      { regNum &&
      <Form.Item 
       shouldUpdate
      >
        {() => (
          <Button
            style={{ marginTop: 8, backgroundColor_off: regNumChecked?'lightgreen':null }}
            icon={<ArrowRightOutlined />}
            loading={cert_loading}
            onClick= {((e) =>  { 
              // when loaded 
              if (!regNumChecked) {
                const opts = {variables: { 
                  clientId:   company.id,
                  regNum:     RegNumItem_ref.current.input.value,
                  today_date: moment().startOf('day'),
                }};
                // alert(JSON.stringify(opts));
                console.log("getCertsByRNtoday: params:", opts);
                getCertsByRNtoday (opts);
              } else {
                alert('unknown state: try to check already checked reg.number');
              } 
            })}
            disabled={
              regNumChecked ||
              (already && !replacement) ||
              !regNum ||
              !!form.getFieldsError().filter(({ errors }) => errors.length).length
            }
          >
            { !cert_loading && !regNumChecked && <> Check <b>{regNum}</b></> }
            { cert_loading && <> Checking ...</>}
            { regNumChecked && <> Checked <b>{regNum}</b></>}
          </Button>
        )}
      </Form.Item>
      }
        <Button 
          disabled={!regNumChecked || cert_error} 
          icon={<ArrowRightOutlined />} 
          style={{ marginTop: 8, backgroundColor: regNumChecked?'lightgreen':null }}
          onClick= {((e) =>  { 
            setStage(stage+1);
          })}
        >Next { regNumChecked && <> ... with <b>{regNum}</b></>}
        </Button>
      {cert_error && <Text type="danger">Check error: {cert_error}</Text>}
      {/*<Text>[nomer:{regNum}][regNumChecked:{regNumChecked}]</Text>*/}
      </>
  },
  /* Select | Drag file ********************************************************/
  {
    title: 'File',
    subTitle: <b>{fileInfo?.name}</b>,
    content: <>
       <UploadCert 
          onSelectFile={(e) => onSelectFile(e)} 
          fileInfo={fileInfo}
          botArgs={{
            tenant:    getUserTenant(),
            user:      auth.login,
            client_id: company.id, 
            certType:  certType,
            regNum:    regNum, 
            operation: operation, 
          }}          
          startUpload={startUpload}
          onBotFinish={onBotFinishHandler} 
       /> 
     </>
  },
  /* Paid | Free **************************************************************/
  {
    title: 'Paid/Free',
    subTitle: <b>{certType}</b>, 
    content: <>
      <Space direction="vertical" size={2}>
      <Text>What type of certificate are you planning to get?</Text>
      <Radio.Group 
        onChange={(e) => { setCertType(e.target.value); setIsCertFreeAcn(false); }} 
        value={certType}
        optionType="button" 
        buttonStyle="solid" 
        style={{ marginTop: 2 }}
       >
        <Space direction="horizontal" size={2}>
          <Radio value="paid">paid</Radio>
          <Radio value="free">free</Radio>
        </Space>
      </Radio.Group>
      { certType == "free" && <>
      <Text type="danger">You are about to generate free of charge certificate!?</Text>
      <Button
        style={{ marginTop: 8 }}
        onClick= {(e) =>  { setIsCertFreeAcn(true); }}
      >Ok</Button>
      </>
      }
        <Button 
          disabled={(certType == "free" && !isCertFreeAcn)} 
          icon={<ArrowRightOutlined />} 
          style={{ marginTop: 8, backgroundColor: !(certType == "free" && !isCertFreeAcn)?'lightgreen':null }}
          onClick= {((e) =>  { 
            setStage(stage+1);
          })}
        >Next { <> ... as <b>{certType}</b></>}</Button>
      </Space>
     </>
  },
  /* Email | Download **********************************************************/
  {
    title: 'Email/Download',
    subTitle: <b>{operation}</b>,
    content: <>
      <Space direction="vertical" size={2}>
      <Text>How to deliver the certificate?</Text>
      <Space direction="horizontal" size={2}>
        <Button
          disabled={botDone}
          name="email"
          icon={<MailOutlined />}
          style={{ marginTop: 8 }}
          onClick={ (e) => { setOperation ("email"); } }
        >Email</Button>
        <Button
          disabled={botDone}
          name="download"
          icon={<DownloadOutlined />}
          style={{ marginTop: 8 }}
          onClick={ (e) => { setOperation ("download"); } }
        >Download</Button>
      </Space>
      <Button 
        disabled={!operation || botDone} 
        loading={startUpload}
        icon={<ArrowRightOutlined />} 
        style={{ marginTop: 8, backgroundColor: operation?'lightgreen':null }}
        onClick= {((e) =>  { 
          onBotStartHandler ();
        })}
      >Create certificate { operation && <> ... and <b>{operation}</b> it</>}</Button>

        {botDone && <>
           {operation == "email" && botDone.err_text && <>
              <Text type={botDone.code == 0 ? "success" : "danger"}>{botDone.err_text}</Text>
              { botDone.code == -1 &&                 
                 <Button
                   comments="Why not `a href` as at CertificatesTable?"
                   type="link"
                   style={{ marginTop: 8, cursor: 'pointer' }}
                   onClick={ (e) => handleDownload(process.env.REACT_APP_SERVER_URL + '/certificates/download/'+botDone.filename, path.basename(botDone.filename)) }
                   onClick_off={ (e) => handleDownload(process.env.REACT_APP_SERVER_URL+'/certificates/download/'+moment().format('YYYY/MM/DD')+'/'+company?.short_name.replace(' ', '_')+'/'+botDone.filename, path.basename(botDone.filename)) }
                 >Download certificate</Button>
              }
              </>
           } 
           {operation == "download" && botDone.err_text && <>
              <Text type={botDone.code == 0 ? "success" : "danger"}>{botDone.err_text}</Text>
              </>
           } 
           <Button
             style={{ marginTop: 8 }}
             onClick={ (e) => onDone(e) }
           >Done</Button>
           </>
         }
      </Space>
    </>
  },
];
  ////////////////////////////////////////////////////////////////
  return (
<div className="site-layout-content" style={{maxHeight: '100%', overflow: "auto", border_debug: 'red solid 1px'}}>

    <Form layout="vertical" form={form}>
    <Steps current={stage} size="small" items={steps} style={{marginBottom: 10, marginTop: 5}}/>
{/*
  * When we move to the next step, 
  * the previous one is unmounted
  * and we can't use it.
  * We'll Mount all steps and just hide currently unnecessary steps 
  *
    <div className="steps-content" style={{paddingTop: 5, border_debug: 'green solid 1px'}}>{steps?.[stage]?.content}</div>
  
*/}

{steps.map((item, index) => (
    <div 
       className="steps-content" 
       style={{
         paddingTop: 5, 
         border_debug: 'green solid 1px', 
         display: index === (stage)? "initial" :"none" 
       }} 
    >
    {item.content}
    </div>
))}


{/****  
    <div className="steps-action" style={{border_debug: 'blue solid 1px'}}>
        {stage < steps.length - 1 && <Button type="primary" onClick={() => next()}>Next</Button>}
        {stage === steps.length - 1 && <Button type="primary" onClick={() => message.success('Processing complete!')}>Done</Button>}
        {stage > 0 && <Button style={{margin: '0 8px',}} onClick={() => prev()}>Previous</Button>}
     </div>
*****/}
    </Form>
</div>
  );
};

// companies_view?
const GET_COMPANIES = gql`
  query Companies {
    companies: companies (
	order_by: [{ name: asc }]
    ) {
      id
      name
      short_name
      country_id
    }
  }
`;

const GET_TRUCKS = gql`
query Trucks ($clientId: Int!) {
    trucks (
        where: { company_id: { _eq: $clientId}},
	   order_by: [{ reg_number: asc }]
    ) {
      reg_number
    }
  }
`;

// use $today due bad date/time capabilities in hasura v2 
const GET_CERTIFICATES_TODAY = gql`
query CertsByRNToday ($clientId: Int!, $regNum: String!, $today_date: timestamptz!) {
  crt_files (
    where: {_and: [ 
      {client_id: { _eq: $clientId }},    
      {gosnomer: { _eq: $regNum }},    
      {f_date: { _gte: $today_date }}
    ]},
    order_by: [{ n_order: asc }]
  ) {
    id
    reg_num: gosnomer
    f_date
    n_order
  }
}
`;

export default CertificatesPage;