import React, { useState } from "react";

import { Link } from "react-router-dom";
import { useStoreon } from "storeon/react";
import { useQuery } from "@apollo/client";

import { SyncOutlined } from "@ant-design/icons";

import gql from "graphql-tag";

import { DatePicker, Input, Table, Row, Col, Space, Button } from "antd";
import { Redirect } from "react-router-dom";

import moment from "moment";
import path from "path";

import CompanySelect from "../../shared/CompanySelect";
import { FilesIcon } from "../../shared/FilesIcon";
import { isSupplier, isAdmin } from "../../../auth/authHelper";

const { RangePicker } = DatePicker;

//date, time: certificate XXXX (regnum) [ download]

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_TABLE_PARAMS = {
  sorter: { field: "f_date", order: "descend" },
  filters: {},
  pagination: {
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  }
};

const CertificatesTable = (props) => {
  const { auth, dispatch } = useStoreon("auth");
  const [tableParams, setTableParams] = useState(DEFAULT_TABLE_PARAMS);

  // or use props?
  //const { selection } = useStoreon("selection");
  //const companyId = selection.companyId;
  const companyId = props.company_id;

  // now we can use only company_id without props.mode
  const all = (props && props.mode === "all");
  const perms = !isAdmin()? {disabled: true} : {};

  ////////////////////////////////////
  const columns = [
  {
    title: "Date",
    dataIndex: "f_date",
    key: "f_date",
    render: date => <>{moment(date).format("DD.MM.YYYY")} </>,
    sortOrder: tableParams.sorter?.field === 'f_date' && tableParams.sorter?.order,
    sorter: (a, b) => {
      let A = a.f_date;
      let B = b.f_date;
      if (A < B) {
        return -1;
      }
      if (A > B) {
        return 1;
      }
      return 0;
    },
    defaultSortOrder: "descend",
    width: "1%",
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => 
     <RangePicker 
      value={tableParams.filters?.["f_date"]?.[0] || []}
      allowClear={true}
      onChange={(value) => {
debugger;
        //overhead ... do it over handleTableChange()
        //setTableParams({...tableParams, filters: {...tableParams.filters, "f_date": value? [value]:null}});

        //setTableParams((prev) => ({...prev, "f_date": value? [value]:null}));
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: tableParams.filters?.["f_date"] || [],
    onFilter: (value, record) => { 
      return moment(record.f_date).isBetween(value[0]._d, value[1]._d, 'day', '[]')
    }
  },
/*
       values.range[0] = values.range[0].startOf('day');
        values.range[1] = values.range[1].endOf('day');
        console.log("filter.range:", values.range);
*/
  {
    dataIndex: "f_date",
    render: date => <>{moment(date).format("HH:mm:ss")} </>,
    width: "1%",
  },
  {
    title_debug: "Name"+' '+tableParams.filters?.["company,short_name"],
    title: "Name",
    dataIndex: ["company","short_name"],
    key: ["company","short_name"],
    sortOrder: JSON.stringify(tableParams.sorter?.field) == JSON.stringify(["company","short_name"]) && tableParams.sorter?.order,
    sorter: (a, b) => {
      let nameA = a.company.short_name?a.company.short_name.toUpperCase():"";
      let nameB = b.company.short_name?b.company.short_name.toUpperCase():"";
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    },
    defaultSortOrder: "ascend",
////////////////////////////////////////
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => <CompanySelect
      value={tableParams.filters?.["company,short_name"]?.[0] || []}
      value_off={tableParams.filters?.["f_date"]?.[0] || []}
      role="transaction_initiator"
      role_text="client"
      my_company_id={auth.company_id}
      allowClear={true}
      style={{width: "300px"}}
      onChange={(value) => {
        //setTableParams({...tableParams, filters: {...tableParams.filters, ["company,short_name"]: value? [value] : null}});
        //setState((prev) => ({...prev, filteredInfo: {...prev.filteredInfo, "supplier.name": value?[value]:null}}));
        setSelectedKeys(value?[value]:[]);
        if (confirm) confirm({ closeDropdown: true });
      }}
    />,
    filteredValue: tableParams.filters?.["company,short_name"] || null,
    onFilter: (value, record) => { 
      //alert(value+' => '+record.company.id+' '+record.company.short_name+' '+(record.company?.id === value));
      //alert(JSON.stringify(value))
      //return record.initiator_company?.id?.indexOf(value) === 0
      return record.company?.id === value
    },

////////////////////////////////////////
  },
  {
    title: () => <Space>Reg.number<Input
            maxLength="10" 
            width="10"
            rem_0='placeholder="search by registarion number ...'
            value={tableParams?.filters?.reg_num}
            rem_1='enterButton'
            rem_2='onSearch={doSearch}'
            onChange={(event) => doSearch(event.target.value)}
      /></Space>
,
    dataIndex: "reg_num",
    filteredValue: [tableParams.filters?.reg_num||""],
    onFilter: (value, record) => {
        return record.reg_num?.includes(value)
    }
  },
  {
    title: "Order",
    dataIndex: "n_order",
    width: "1%",
  },
  {
    title: "",
    render: (r) => (
      <div style={{ float: "right" }}> 
{/*
        <Button type="link" size={size}
           onClick={ (e) => handleDownload(process.env.REACT_APP_SERVER_URL + '/certificates/download/'+botDone.filename, path.basename(botDone.filename)) }
        >Download
        </Button>

        <Link to={process.env.REACT_APP_SERVER_URL + '/certificates/download/'+path.basename(record.filename)} target="_blank" download>Download</Link>

        // wrong port
        <Link to={'download/'+moment(r.f_date).format('YYYY/MM/DD')+'/'+r.company?.short_name.replace(' ', '_')+'/'+r.filename} target="_blank" download style={{cursor: 'pointer'}}>Download</Link>

*/}
        <a href={process.env.REACT_APP_SERVER_URL+'/certificates/download/'+moment(r.f_date).format('YYYY/MM/DD')+'/'+r.company?.short_name.replace(' ', '_')+'/'+r.filename} target="_blank" download style_off={{cursor: 'pointer'}}>Download</a>


{/*
            <L3ink to="/logout" onClick={clickLogout} style={{cursor: 'pointer'}}>[Logout]</Link>
*/}
      </div>
    ),
  }
  ];
  ////////////////////////////////////
  /*
  const { loading, error, data } = {loading: false, error: false, data: { certificates: [
{
    id: 1,
    client_id: 1,
    reg_num: 'XX123XX',
    f_date: '22.11.2024 23:15:12',
    n_order: 1,
    company: {
      name: 'IKEA Ltd',
      short_name: 'IKEA'
    }    
}
]} };
  */
  const { loading, error, data, refetch } = useQuery(companyId? GET_CERTIFICATES: GET_CERTIFICATES_ALL, {
//  skip: !companyId,
    variables: companyId? { client_id: companyId } : {},
  });

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const filteredData = data.certificates;
/*
  const filteredData = filter
     ? data.certificates.filter((user) =>
        user
        ? user.display_name?.toLowerCase().includes(filter.toLowerCase())
        || user.first_name?.toLowerCase().includes(filter.toLowerCase())
        || user.last_name?.toLowerCase().includes(filter.toLowerCase())
        : false,
     )
     :data.certificates;
*/
  const doSearch = (value) => {
    console.log('CertificatesTable.doSearch:', value);
    setTableParams({...tableParams, filters: {...tableParams.filters, reg_num: value?.toUpperCase()}});
    // refetch();
  };

  console.log("CertificatesTable: got result: ", data); 

 const handleTableChange = (pagination, filters, sorter, extra) => {
    //alert(JSON.stringify(filters));
    setTableParams({
      pagination,
      filters,
      sorter,
    });
    if (extra.action === "filter") {
      console.log('handleTableChange: extra:', extra);
    }
 };


  return (
    <div>
     <Input.Group>
      <Row gutter={8}>
{/*JSON.stringify(tableParams)*/}

{/*
  * create certificates at companies tab? 
  *
  *
   companyId && //isAdmin() &&
        <Col span={1}>
      <FormBtn component="certificate" company_id={companyId} action="add" {...perms}/>
        </Col>
  */
}
        <Col span={4}>
         <Button size="small" icon={<SyncOutlined />} onClick={(e) => { refetch(); }} >Refetch</Button>

{/*
      <Input
            maxLenght="10"
            placeholder="search by registarion number ..."
            value={tableParams?.filters?.reg_num}
            _enterButton
            _onSearch={doSearch}
            onChange={(event) => doSearch(event.target.value)}
      />
*/}
        </Col>
      </Row>
     </Input.Group>

      <Table
        pagination={filteredData?.length > 1}
        rowKey="id"
        columns={columns}
        _dataSource={data && data.certificates}
        dataSource={filteredData}
        style={{ margin: 0 }}
        size="small"
        onChange={handleTableChange}
      />
    </div>
  );
};


const GET_CERTIFICATES = gql`
query CertificatesByClient ($client_id: Int!) {
  certificates: crt_files (
    where: {client_id: {_eq: $client_id}},
    order_by: [{f_date: desc}, {client_id: asc}, {n_order: asc}]
  ){
    id
    client_id
    reg_num: gosnomer
    f_date
    n_order
    filename
    company {
      id
      name
      short_name
    }    
  }
}
`;

const GET_CERTIFICATES_ALL = gql`
query CertificatesByClient ($client_id: Int) {
  certificates: crt_files (
    order_by: [{f_date: desc}, {client_id: asc}, {n_order: asc}]
  ){
    id
    client_id
    reg_num: gosnomer
    f_date
    n_order
    filename
    company {
      id
      name
      short_name
    }    
  }
}
`;


export default CertificatesTable;
